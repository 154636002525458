// import { useAppProviderContext } from 'framework/components/Application'
// import { computed } from 'vue';
// import { lowerFirst } from 'lodash-es';
export function useDesign(scope: string) {
  // const values = useAppProviderContext()
  // const $style = cssModule ? useCssModule() : {};
  
  // const style: Record<string, string> = {};
  // if (cssModule) {
  //   Object.keys($style).forEach((key) => {
  //     // const moduleCls = $style[key];
  //     const k = key.replace(new RegExp(`^${values.prefixCls}-?`, 'ig'), '');
  //     style[lowerFirst(k)] = $style[key];
  //   });
  // }
  return {
    // prefixCls: computed(() => `${values.prefixCls}-${scope}`),
    prefixCls: `bloom-${scope}`,
    prefixVar: 'bloom',
    // prefixVar: values.prefixCls,
    // style,
  }
}
